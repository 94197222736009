import React from 'react';
import PropTypes from 'prop-types';
import { PageSEO, useSEO } from '../components/SEO';

const Sitemap = ({ location }) => {
  //
  const { pathname } = location;
  const seo = useSEO(pathname);

  return (
    <article>
      <PageSEO title={seo.title} description={seo.description} pathname={pathname} featureImage="og-sitemap.jpg" />
      Insert Sections here
    </article>
  );
};

export default Sitemap;

Sitemap.propTypes = {
  location: PropTypes.object.isRequired,
};
